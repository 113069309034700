import React from 'react';
import './WhatsAppFAB.css'; // Create this CSS file to style the FAB

const WhatsAppFAB = () => {
  return (
    <a
      href="https://wa.me/50760218463"
      className="whatsapp-fab"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/whats.webp" alt="WhatsApp" />
    </a>
  );
};

export default WhatsAppFAB;