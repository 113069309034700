import React from "react";
import AwsManagement from "../../components/Landing/AwsManagement";
import Contact from "../../components/Landing/Contact";
import DevelopmentServices from "../../components/Landing/DevelopmentServices";
import Footer from "../../components/Landing/Footer";
import Hero from "../../components/Landing/Hero";
import Navbar from "../../components/Landing/Navbar";
import TechTalentRecruitment from "../../components/Landing/TechTalentRecruitment";
import WhyChooseUs from "../../components/Landing/WhyChooseUs";
import { LanguageSwitcher } from "../../components/LanguageSwitcher";
import WhatsAppFAB from "../../components/WhatsAppFAB"; // Import the WhatsAppFAB component

const Landing = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <WhyChooseUs/>
      <TechTalentRecruitment/>
      <AwsManagement/>
      <DevelopmentServices/>
      <Contact/>
      <Footer/>
      <WhatsAppFAB /> {/* Add the WhatsAppFAB component */}
    </>
  );
};

export default Landing;
